import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./home/home.module').then((m) => m.HomePageModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardPageModule),
  },
  {
    path: 'leaderboard',
    loadChildren: () =>
      import('./leaderboard/leaderboard.module').then(
        (m) => m.LeaderboardPageModule
      ),
  },
  {
    path: 'leaderboard-modal',
    loadChildren: () =>
      import('./leaderboard-modal/leaderboard-modal.module').then(
        (m) => m.LeaderboardModalPageModule
      ),
  },
  {
    path: 'comparsion-board',
    loadChildren: () =>
      import('./comparsion-board/comparsion-board.module').then(
        (m) => m.ComparsionBoardPageModule
      ),
  },
  {
    path: 'ownership',
    loadChildren: () =>
      import('./ownership/ownership.module').then((m) => m.OwnershipPageModule),
  },
  {
    path: 'h2hownership',
    loadChildren: () =>
      import('./h2hownership/h2hownership.module').then(
        (m) => m.h2hOwnershipPageModule
      ),
  },
  {
    path: 'topcaptain',
    loadChildren: () =>
      import('./topcaptain/topcaptain.module').then(
        (m) => m.TopCaptainPageModule
      ),
  },
  {
    path: 'bench',
    loadChildren: () =>
      import('./bench/bench.module').then((m) => m.BenchPageModule),
  },
  {
    path: 'topmanager',
    loadChildren: () =>
      import('./topmanager/topmanager.module').then(
        (m) => m.TopmanagerPageModule
      ),
  },
  {
    path: 'topmanagerh2h',
    loadChildren: () =>
      import('./topmanagerh2h/topmanagerh2h.module').then(
        (m) => m.Topmanagerh2hPageModule
      ),
  },
  {
    path: 'historic',
    loadChildren: () =>
      import('./historic/historic.module').then((m) => m.HistoricPageModule),
  },
  {
    path: 'historic-modal',
    loadChildren: () =>
      import('./historic-modal/historic-modal.module').then(
        (m) => m.HistoricModalPageModule
      ),
  },
  {
    path: 'selectplayer',
    loadChildren: () =>
      import('./selectplayer/selectplayer.module').then(
        (m) => m.SelectplayerPageModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
